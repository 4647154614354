
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IPrompt } from '@/interfaces';
import { readPrompts } from '@/store/main/getters';
import { dispatchGetPrompts } from '@/store/main/actions';

@Component
export default class Prompts extends Vue {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Template',
      sortable: true,
      value: 'template',
      align: 'left',
    },
    {
      text: 'Type',
      sortable: true,
      value: 'type',
      align: 'left',
    },
    {
      text: 'Variables',
      sortable: true,
      value: 'variables',
      align: 'left',
    },
    {
      text: 'Tags',
      sortable: true,
      value: 'tags',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];
  get prompts() {
    return readPrompts(this.$store);
  }

  public async mounted() {
    await dispatchGetPrompts(this.$store);
  }
}
